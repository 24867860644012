import React, {createContext, useCallback, useMemo, useState} from 'react';
import Storage from 'utils/LocalStorage';
import {signin} from 'services/auth';
import {api} from 'services/api';
import {ApiResponse} from 'services/types';
import {getUserByEmail} from 'services/user';

export const USER_KEY = 'SENAIXP:USER';
export const TOKEN_KEY = 'SENAIXP:TOKEN';
export const AVATAR_KEY = 'SENAIXP:USER_AVATAR';

export type Role = 'Professor' | 'Aluno'
export interface User {
  name: string;
  email: string;
  role: Role;
  avatar: string | null;
}

export interface AuthState {
  token: string;
  user: User;
}

export interface Credentials {
  email: string;
  password: string;
  type: string;
}

export interface AuthContextProps {
  signIn(credentials: Credentials): Promise<ApiResponse>
  fetchUserAvatar: (userEmail: string) => Promise<void>
  signOut(): void
  auth: AuthState
  isProfessor: boolean,
  isStudent: boolean,
  isAuthenticated: boolean,
  userAvatar: string | null,
}

export const USER_LABEL = {
  professor: 'Professor',
  student: 'Aluno',
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const getUser = (): AuthState => {
  const token = Storage.get(TOKEN_KEY);
  const user = Storage.get(USER_KEY);

  if (token && user) {
    api.defaults.headers.common.authorization = `Bearer ${token}`;
    return {token: token, user: JSON.parse(user)};
  }

  return {} as AuthState;
};

const getAvatar = () => {
  return Storage.get(AVATAR_KEY); 
};

interface Props {
  children?: React.ReactNode;
}

// eslint-disable-next-line max-len
const AuthProvider: React.FC<React.PropsWithChildren<Props>> = ({children}) => {
  const [auth, setAuth] = React.useState<AuthState>(getUser());
  const [userAvatar, setUserAvatar] = useState<string | null>(getAvatar());


  const fetchUserAvatar = async (userEmail: string) => {
    const user = await getUserByEmail(userEmail);
    if (!user) return; 

    const {avatar_url} = user; 
    setUserAvatar(avatar_url);
    Storage.save(AVATAR_KEY, avatar_url);
  };

  const signIn = async (credentials: Credentials) => {
    const {email, password, type} = credentials;
    const response = await signin(email, password, type);
    if (response && !response.error) {
      const {token, user: userInfo} = response.data;
      const {email, name, role_name} = userInfo;
      const user: User = {email, name, role: role_name, avatar: null};
      api.defaults.headers.common.authorization = `Bearer ${token}`;
      Storage.save(TOKEN_KEY, token);
      Storage.save(USER_KEY, user); 
      setAuth({token, user});
      fetchUserAvatar(user.email);
    }
    return response;
  };

  const signOut = useCallback(() => {
    Storage.delete(TOKEN_KEY);
    Storage.delete(USER_KEY);
    Storage.delete(AVATAR_KEY);
    setAuth({} as AuthState);
  }, []);

  const isProfessor = useMemo(() => {
    return auth && auth.user && auth.user.role === 'Professor';
  }, [auth]);

  const isStudent = useMemo(() => {
    return auth && auth.user && auth.user.role === 'Aluno';
  }, [auth]);

  const isAuthenticated: boolean = useMemo(() => {
    return isProfessor || isStudent; 
  }, [auth]);


  return (
    <AuthContext.Provider value={{
      auth,
      isStudent,
      isProfessor,
      userAvatar,
      isAuthenticated,
      signIn,
      signOut,
      fetchUserAvatar,
    }}>
      {children}
    </AuthContext.Provider>
  );
};


export {AuthContext, AuthProvider};
