import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface Alternative {
  statement: string,
  isCorrect: boolean,
}

export interface QuizQuestion {
  statement: string,
  alternatives: Alternative[],
}

const questions: QuizQuestion[] = [];

export const quiz = createSlice({
  name: 'quiz',
  initialState: {questions},
  reducers: {
    updateQuiz: (state, action: PayloadAction<QuizQuestion>) => {
      state.questions.push(action.payload);
    },
    resetQuiz: (state) => {
      state.questions = [];
    },
  },
});


export const {resetQuiz, updateQuiz} = quiz.actions;
export default quiz.reducer;
