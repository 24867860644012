import React from 'react';
import Lottie from 'react-lottie';
import LoadingLottie from 'assets/lotties/loading-lottie.json';

import {Container} from './styles';

const LoadingScreen: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container >
      <Lottie
        options={defaultOptions}
        height={250}
        width={250}
      />
    </Container>
  );
};

export default LoadingScreen;
