import {v4 as uuidv4} from 'uuid';
import {PublicationsMap} from 'context/PublicationContext';
import {QuizQuestion} from 'features/quiz';
import {PublicationTourForm} from 'pages/CreateTour';
import {PublicationFormValues} from 'pages/CreateVideo';
import {Publication, PublicationSimplified} from 'services/types';
import {getVideoIDFromURL, normalizeMatterportUrl} from 'utils/videos';

interface Tags {
  id: string;
  text: string;
}

type Quiz = QuizQuestion[];

const YOUTUBE_BASE_URL = 'https://www.youtube.com/watch?v=';

const createPublicationMap = (publications: PublicationSimplified[]) => {
  const publicationsMap: PublicationsMap = {};
  publications.forEach((publication) => {
    const {video_link: videoID} = publication;
    publicationsMap[videoID] = publication;
  });
  return publicationsMap;
};


interface PublicationOptions {
  formData: PublicationFormValues,
  questionnaire?: Quiz,
  is360Video?: boolean,
  isLive?: boolean,
  isPublic?: boolean,
  isHallVideo?: boolean
}

function createPublication({
  formData,
  questionnaire={} as Quiz,
  is360Video=false,
  isLive=false,
  isPublic=false,
  isHallVideo=false,
}: PublicationOptions) {
  const video_link = getVideoIDFromURL(formData.video_link);
  const [selectedModule] = formData.module;
  const [courseSelected] = formData.course;
  const {name, description} = formData;
  const {value: course_id} = courseSelected;
  const {value: course_module} = selectedModule;

  const tags: Tags[] = formData.tags.map((tag: string) => {
    const id = uuidv4();
    return {id, text: tag};
  });


  const startTime = formData.startTime ?
    new Date(formData.startTime) : undefined;

  const endTime = formData.endTime ?
    new Date(formData.endTime) : undefined;

  return {
    name,
    course_id,
    description,
    video_link,
    questionnaire,
    course_module,
    tags,
    isLive,
    isPublic,
    is360Video,
    isHallVideo,
    startTime,
    endTime,
  };
}

function createFormData(publication: Publication) {
  const {
    name,
    description,
    questionnaire,
    isPublic,
    video_link: videoID,
    course: selectedCourse,
  } = publication;

  const {name: courseName, id: courseId} = selectedCourse;

  const course = [{label: courseName, value: courseId}];

  const module = selectedCourse.modules.map((module) => {
    const {grade} = module;
    const [{title, credits}] = grade;
    return {value: credits, label: title};
  });

  const tags = publication.tags.map((tag) => tag.text);
  const video_link = YOUTUBE_BASE_URL + videoID;

  return {
    name,
    description,
    questionnaire,
    course,
    module,
    isPublic,
    video_link,
    tags,
  };
}


function createPublicationTour(form: PublicationTourForm) {
  const tourLink = normalizeMatterportUrl(form.tourLink);
  return {...form, tourLink};
}


export {
  createPublication,
  createPublicationTour,
  createPublicationMap,
  createFormData,
};
