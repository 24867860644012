import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type Switchers = { [fieldName: string]: boolean | undefined }

interface PayloadSwitcher {
  fieldName: string;
  fieldValue?: boolean;
}

const switchers: Switchers = {};

export const formSwitchers = createSlice({
  name: 'switchersForm',
  initialState: {switchers},
  reducers: {
    updateSwitcher: (state, action: PayloadAction<PayloadSwitcher>) => {
      state.switchers[action.payload.fieldName] = action.payload.fieldValue;
    },
  },
});


export const {updateSwitcher} = formSwitchers.actions;
export default formSwitchers.reducer;
