import {configureStore} from '@reduxjs/toolkit';
import quizReducer from 'features/quiz';
import switcherReducer from 'features/switchers';
import formsReducer from 'features/form';


const store = configureStore({
  reducer: {
    quiz: quizReducer,
    switchers: switcherReducer,
    forms: formsReducer,
  },
});

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export default store;
export type {RootState, AppDispatch};

