import {api} from './api';

export interface UserProfile {
  avatar: null | string; 
  avatar_url: null | string;
  cpf: string; 
  email: string; 
  id: string; 
  name: string; 
  role: number; 
  role_name: string; 
}

const getUserByEmail = async (email: string): Promise<UserProfile | null> => {
  try {
    const route = `/users/dashboard/specific/email/${email}`;
    const response = await api.get(route);
    return response.data as UserProfile;
  } catch (error) {
    console.log(error);
  }
  return null;
};

const saveAvatar = async (imageFile: any) => {
  try {
    const formData = new FormData();
    formData.append('avatar', imageFile);

    const route = `users/dashboard/profile/avatar`;
    const response = await api.patch(route, formData);
    return response.data;
  } catch (error) {
    console.log(error);
  }
  return null;
};


export {
  getUserByEmail,
  saveAvatar,
};
