import useAuth from 'hooks/Auth';
import React from 'react';
import {RouteProps} from 'types';
import {Navigate} from 'react-router-dom';

const IsPublic: React.FC<RouteProps> = ({Component}) => {
  const {auth} = useAuth();
  return !auth.user ? Component : <Navigate to={'/u/publications/videos'} />;
};

export default IsPublic;
