/* eslint-disable max-len */
import React from 'react';
import {ContainerFlex, FlexContainerProps} from './styles';

interface Props extends FlexContainerProps {
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const Container: React.FC<Props> = ({children, onClick, ...rest}) => {
  return (
    <ContainerFlex {...rest} onClick={onClick}>
      {children}
    </ContainerFlex>
  );
};

export {Container};
export default Container;
