/* eslint-disable max-len */
import styled, {css} from 'styled-components';

export interface ButtonPropsContainer {
  flex?: number
  textColor?: string
  background?: string
  backgroundHover?: string
  colorTextHover?: string
  link?: boolean

  width?: string,
  height?: string,


  mh?: string, // margin-horizontal
  mv?: string, // margin-vertical
  ph?: string, // padding-horizontal
  pv?: string, // padding-vertical

  ml?: string, // margin-left
  mr?: string, // margin-right
  mt?: string, // margin-top
  mb?: string, // margin-bottom

  pl?: string, // padding-left
  pr?: string, // padding-right
  pt?: string, // padding-top
  pb?: string, // padding-bottom

  p?: string // padding
  m?: string // margin

  border?: string;
  borderB?: string;
  borderT?: string;
  borderR?: string;
  borderL?: string;

  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify';
}


export const Container = styled.button<ButtonPropsContainer>`
  width: 100%;
  font-size: 1em;  
  margin: 5px 0px;
  min-width: 10rem;
  padding: 10px 20px;
  border-radius: 3px;
  border: none;
  cursor: pointer !important; 

  text-align: ${(props) => props.textAlign && props.textAlign};

  overflow: hidden;
  position: relative;
  flex: ${(props) => props.flex && props.flex};
  color: ${(props) => props.textColor && props.textColor};
  background-color: ${(props) => props.background && props.background} ;
  border: ${(props) => props.border && `1px solid ${props.border}`};
  

  width: ${({width}) => width && width};
  height: ${({height}) => height && height};

  margin: ${({m}) => m && m};
  padding: ${({p}) => p && p};

  margin-left: ${({ml}) => ml && ml};
  margin-right: ${({mr}) => mr && mr};
  margin-top: ${({mt}) => mt && mt};
  margin-bottom: ${({mb}) => mb && mb};

  padding-left: ${({pl}) => pl && pl};
  padding-right: ${({pr}) => pr && pr};
  padding-top: ${({pt}) => pt && pt};
  padding-bottom: ${({pb}) => pb && pb};

  border-top: ${({borderT}) => borderT && borderT};
  border-bottom: ${({borderB}) => borderB && borderB};
  border-left: ${({borderL}) => borderL && borderL};
  border-right: ${({borderR}) => borderR && borderR};

  ${({link}) => link && css`
    text-decoration: underline;
  `}

  ${({mh}) => mh && css`
    margin-left: ${mh};
    margin-right: ${mh};
  `};

  ${({mv}) => mv && css`
    margin-top: ${mv};
    margin-bottom: ${mv};
  `};

  ${({pv}) => pv && css`
    padding-top: ${pv};
    padding-bottom: ${pv};
  `};

  ${({ph}) => ph && css`
    padding-left: ${ph};
    padding-right: ${ph};
  `};

  
  
  &:hover {
    background: ${(props) => props.backgroundHover && props.backgroundHover};
    color: ${(props) => props.colorTextHover && props.colorTextHover};
    border: ${(props) => props.background && `1px solid ${props.border}`};
  }
`;

interface SpanProps {
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify';
}

export const Span = styled.span<SpanProps>`
  flex: 1;
  text-align: ${(props) => props.textAlign && props.textAlign};
  font-size: 1em;
`;
