/* eslint-disable max-len */

import React, {lazy, Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';

import IsPrivate from 'middlewares/IsPrivate';
import IsPublic from 'middlewares/IsPublic';
import LoadingScreen from 'components/LoadingScreen';

const PrivateRoutes = lazy(() => import('./private.routes'));
const PublicRoutes = lazy(() => import('./public.routes'));


const ApplicationRoutes: React.FC = () => {
  return (
    <BrowserRouter basename='/experience' >
      <Routes>
        <Route path='/*' element={
          <Suspense fallback={<LoadingScreen/>}>
            <IsPublic Component={<PublicRoutes />} />
          </Suspense>
        } />

        <Route path='/u/*' element={
          <Suspense fallback={<LoadingScreen/>}>
            <IsPrivate Component={<PrivateRoutes />}/>
          </Suspense>
        } />
      </Routes>
    </BrowserRouter>
  );
};

export default ApplicationRoutes;
