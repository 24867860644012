import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PublicationFormValues} from 'pages/CreateVideo';

const initialState = {
  createPublicationForm: {} as PublicationFormValues,
};

export const forms = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    updateFormValue: (state, action: PayloadAction<PublicationFormValues>) => {
      state.createPublicationForm = action.payload;
    },

    resetFormBackup: (state) => {
      state.createPublicationForm = {} as PublicationFormValues;
    },
  },
});


export const {updateFormValue, resetFormBackup} = forms.actions;
export default forms.reducer;
