import React, {PropsWithChildren} from 'react';
import Typography from 'components/Typography';
import {StyledDialog} from './styles';
import {ReactComponent as SuccessIcon} from 'assets/img/icons/success.svg';
import {ReactComponent as ErrorIcon} from 'assets/img/icons/error.svg';


export type DialogType = 'success' | 'error'

interface Props {
  open: boolean,
  title?: string,
  subtitle?: string,
  type?: DialogType,
  onOpenChange?(open: boolean): void,
}

const svgIcon = {
  success: <SuccessIcon />,
  error: <ErrorIcon />,
};


const NotificationDialog: React.FC<PropsWithChildren<Props>> = ({
  open,
  type = 'success',
  title = '',
  subtitle = '',
  children,
  onOpenChange,
}) => {
  return (
    <StyledDialog.Root
      open={open}
      onOpenChange={onOpenChange}
    >
      <StyledDialog.Portal>
        <StyledDialog.Overlay />
        <StyledDialog.Content type={type} >
          <StyledDialog.ContentLayerOne>
            <StyledDialog.ContentLayerTwo>
              {svgIcon[type]}
              <Typography
                variant='h1'
                textAlign='center'
                width='80%'
              >
                {title}
              </Typography>
              <Typography
                variant='h3'
                textAlign='center'
                width='80%'
              >
                {subtitle}
              </Typography>
              {children}
              <StyledDialog.Close asChild />
            </StyledDialog.ContentLayerTwo>
          </StyledDialog.ContentLayerOne>
        </StyledDialog.Content>
      </StyledDialog.Portal>
    </StyledDialog.Root>
  );
};


export {NotificationDialog};
