import axios from 'axios';


/* eslint-disable max-len */
const matterportRegex = /^(?:https?:\/\/)?(?:my\.matterport\.com\/show\/\?m=|matterport\.com\/discover\/space\/)(\w+)$/;
const youtubeUrlRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

function getVideoIDFromURL(url: string): string | null {
  if (url !== undefined && url !== null) {
    const match = url.match(youtubeUrlRegex);
    return (match && match[7].length === 11)? match[7] : null;
  }
  return null;
}

function validateVideoURL(url: string | undefined): boolean {
  return url !== undefined && url.match(youtubeUrlRegex) !== null;
}

function matchesMatterportUrl(url: string): boolean {
  return matterportRegex.test(url);
}

function extractMatterportUrlId(url: string): string | null {
  const match = url.match(matterportRegex);
  return match ? match[1] : null;
}

// This method should be used only if the url is already valid.
function normalizeMatterportUrl(url: string): string {
  const uniqueId = extractMatterportUrlId(url);
  return `https://my.matterport.com/show/?m=${uniqueId}`;
}

function getYoutubeThumbnailURL(videoID: string): string {
  return `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`;
}

function getMatterPortThumbnail(tourID: string) {
  return `https://my.matterport.com/api/v1/player/models/${tourID}/thumb`;
}


async function validateThumbnail(url: string): Promise<boolean> {
  const response = await axios.get(url, {responseType: 'arraybuffer'});
  return (response.status === 200 && response.headers['content-type'].startsWith('image/'));
}

export {
  getVideoIDFromURL,
  validateVideoURL,
  getYoutubeThumbnailURL,
  validateThumbnail,
  extractMatterportUrlId,
  getMatterPortThumbnail,
  matchesMatterportUrl,
  normalizeMatterportUrl,
};
