import {SignUpData} from 'types';

import {api, defaultApiResponse} from './api';
import {ApiResponse} from './types';

async function signin(
    email: string, 
    password: string, 
    type: string,
): Promise<ApiResponse> {
  const signInResponse = {...defaultApiResponse};

  try {
    const route = '/dashboard/sessions';
    const response = await api.post(route, {email, password, type});
    if (response && response.data && response.status) {
      const {data, status} = response;
      signInResponse.data = data;
      signInResponse.status = status;
    }
  } catch (error: any) {
    signInResponse.error = true;
    if (error && error.response) {
      const {response} = error;
      const {message} = response.data;
      signInResponse.message = message;
    }
  }

  return signInResponse;
}


async function signup(user: SignUpData): Promise<ApiResponse> {
  const signUpResponse = {...defaultApiResponse};

  try {
    const route = '/users/dashboard';
    const response = await api.post(route, user);

    if (response && response.data && response.status) {
      const {data, status} = response;
      signUpResponse.data = data;
      signUpResponse.status = status;
    }
  } catch (error: any) {
    signUpResponse.error = true;
    if (error && error.response) {
      const {response} = error;
      const {message} = response.data;
      signUpResponse.message = message;
    }
  }

  return signUpResponse;
}


async function sendEmailtoResetPassword(emailValue: string) {
  const sendEmailResponse = {...defaultApiResponse};

  try {
    const route = '/password/forgot';
    const response = await api.post(route, {email: emailValue});

    if (response && response.data && response.status) {
      const {data, status} = response;
      sendEmailResponse.data = data;
      sendEmailResponse.status = status;
      const {message} = response.data;
      sendEmailResponse.message = message;
    }
  } catch (error: any) {
    sendEmailResponse.error = true;
    if (error && error.response) {
      const {response} = error;
      const {message} = response.data;
      sendEmailResponse.message = message;
    }
  }
  return sendEmailResponse;
}

async function resetPassword(token: string, newPassword: string) {
  const resetPasswordResponse = {...defaultApiResponse};

  try {
    const route = `password/dashboard/reset/?token=${token}`;
    const response = await api.post(route, {password: newPassword});
    if (response && response.data && response.status) {
      const {data, status} = response;
      resetPasswordResponse.data = data;
      resetPasswordResponse.status = status;
      const {message} = response.data;
      resetPasswordResponse.message = message;
    }
  } catch (error: any) {
    resetPasswordResponse.error = true;
    if (error && error.response) {
      const {response} = error;
      const {message} = response.data;
      resetPasswordResponse.message = message;
    }
  }
  return resetPasswordResponse;
}

export {signin, signup, resetPassword, sendEmailtoResetPassword};
