
/* eslint-disable max-len */
const pallette = {
  primaryBlue: '#05013E',
  secondaryBlue: '#1A0230',

  primaryDarkLilac: '#2E005A',
  primaryDarkLilacHome: '#55087f',
  secondaryDarkLilac: '#4D157E',

  primaryLilac: '#4F31B0',
  secondaryLilac: '#8E2CCD',

  secondaryGreen: '#5AED7C',
  lemon: '#06FF41',
  primaryGreen: '#3D9882',
  darkGreen: ' #0C6B54',
  violet: '#C315FF',
  violetHover: '#9400c5',

  white: '#FFFFFF',
  whiteOpacity: '#FFFFFF80',
  gray: '#CDCDCD',
  inputBorder: '#A3A3A3',

  buttonHover: '#266D5C',
  backgroundButton: '#3D9882',
  buttonClick: '#14C399',

  professorButtonBack: '#3D9882',
  professorButtonHover: '',

  studentButtonBack: '#B800E6',
  studentButtonHover: '#7b009a',

  transparent: 'rgba(0,0,0,0)',
  link: '#00C2FF',

  borderSuccess: 'linear-gradient(to bottom, #2BAA7C, rgba(0,0,0,0)) 1',
  borderError: 'linear-gradient(to bottom, #FF3C40, rgba(0,0,0,0)) 1',

  formProfessor: 'linear-gradient(136.05deg, rgba(90, 237, 124, 0.5) 0.31%, rgba(28, 74, 38, 0.5) 71.74%, rgba(0, 0, 0, 0.5) 108.72%)',
  formStudent: 'linear-gradient(137.99deg, rgba(180, 41, 154, 0.5) -9.46%, rgba(89, 20, 76, 0.5) 19.54%, rgba(0, 0, 0, 0.5) 76.31%)',
  backgroundGradient: 'linear-gradient(-45deg, #181456, #0A061E ,  #4E075F, #402625)',
  blackLow: '#262426',

  metaverse: 'linear-gradient(181.24deg, #3FAE43 1.55%, #3A8B7A 109.35%)',
  borderMenuButtons: '#4F31B0',
};

export default pallette;
