/* eslint-disable max-len */
import {AxiosResponse} from 'axios';
import {PublicationTour} from 'pages/CreateTour';
import {Publication, PublicationsTours} from 'services/types';
import {Publication as PublicationItem, PublicationTourOnline} from 'types/publications';
import {api, defaultApiResponse} from './api';
import {Publications} from './types';

async function sendPublication(courseId: string, publication: any) {
  const publicationResponse = {...defaultApiResponse};
  try {
    const route = `/publication/dashboard/${courseId}`;
    const response = await api.post(route, publication);

    const {data, status} = response;
    publicationResponse.data = data;
    publicationResponse.status = status;
  } catch (error: any) {
    publicationResponse.error = true;
    if (error && error.response) {
      const {response} = error;
      const {message} = response.data;
      publicationResponse.message = message;
    }
  }
  return publicationResponse;
}


async function editPublication(publicationId:string, publication: any) {
  const publicationResponse = {...defaultApiResponse};
  try {
    const route = `/publication/dashboard/${publicationId}`;
    const response = await api.put(route, publication);

    const {data, status} = response;
    publicationResponse.data = data;
    publicationResponse.status = status;
  } catch (error: any) {
    publicationResponse.error = true;
    if (error && error.response) {
      const {response} = error;
      const {message} = response.data;
      publicationResponse.message = message;
    }
  }
  return publicationResponse;
}

async function sendPublicationTour(publication: PublicationTour) {
  const publicationResponse = {...defaultApiResponse};
  try {
    const route = `tour/dashboard`;
    const response = await api.post(route, publication);

    if (response && response.data && response.status) {
      const {data, status} = response;
      publicationResponse.data = data;
      publicationResponse.status = status;
    }
  } catch (error: any) {
    publicationResponse.error = true;
    if (error && error.response) {
      const {response} = error;
      const {message} = response.data;
      publicationResponse.message = message;
    }
  }
  return publicationResponse;
}

async function editPublicationTour(tourId: string, tour: PublicationTour) {
  const publicationResponse = {...defaultApiResponse};
  try {
    const route = `/tour/dashboard/${tourId}`;
    const response = await api.put(route, tour);

    if (response && response.data && response.status) {
      const {data, status} = response;
      publicationResponse.data = data;
      publicationResponse.status = status;
    }
  } catch (error: any) {
    publicationResponse.error = true;
    if (error && error.response) {
      const {response} = error;
      const {message} = response.data;
      publicationResponse.message = message;
    }
  }
  return publicationResponse;
}


async function getSortedPublicationsByUser() {
  try {
    const route = `/publication/dashboard/list`;
    const response = await api.get(route, {params: {
      owned_by_user: true,
      sort: 'created_at',
      sort_type: 'Decrescente',
    }});
    return response;
  } catch (error) {
    // logged on interceptors
  }
  return null;
}

async function getPublicationsLives() {
  try {
    const route = `/publication/dashboard/list`;
    const response = await api.get(route, {params: {
      owned_by_user: true,
      sort: 'created_at',
      sort_type: 'Decrescente',
      keywords: ['isLive:true'],
    }});

    const {
      object_list: results,
      max_pages: pages,
      max_items: size,
    } = response.data;

    return {results, pages, size};
  } catch (error) {
    // logged on interceptors
  }
  return null;
}


// get most recent from first page, this is a temporary solution.
// get this object from backend is a best approach.
async function getLastVideoSent(): Promise<PublicationItem | null> {
  const response = await getSortedPublicationsByUser();
  if (response && response.data) {
    const {object_list: publications} = response.data;
    if (Array.isArray(publications)) {
      const [publicationData] = publications;
      return publicationData;
    }
  }
  return null;
};

async function deleteVideo(id: string) {
  try {
    const route = `publication/dashboard/${id}`;
    const response = await api.delete(route);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

async function deleteTour(tourId: string) {
  try {
    const route = `/tour/dashboard/${tourId}`;
    const response = await api.delete(route);
    return response;
  } catch (error) {
    return error;
  }
}

async function getMostViewedVideo(): Promise<PublicationItem | null> {
  const response = await getSortedPublicationsByUser();
  if (response && response.data) {
    const {object_list: publications} = response.data;
    if (Array.isArray(publications) && publications.length !== 0) {
      const publicationData: PublicationItem = publications.reduce((previous, current) => {
        return previous.visualizations > current.visualizations? previous : current;
      });
      return publicationData;
    }
  }
  return null;
};

async function getPublications(keywords: string[] = []): Promise<AxiosResponse<Publications> | null> {
  try {
    const route = `/publication/dashboard/list`;
    const response = await api.get(route, {
      params: {
        sort: 'created_at',
        sort_type: 'Decrescente',
        keywords,
      },
    });
    return response;
  } catch (error) {
    // logged on interceptors
  }
  return null;
}

async function getPublicPublications(keywords: string[] = []): Promise<AxiosResponse<Publications> | null> {
  try {
    keywords.push('isLive:false');
    const route = `/publication/dashboard/list/public`;
    const response = await api.get(route, {
      params: {
        sort: 'created_at',
        sort_type: 'Decrescente',
        keywords,
      },
    });
    return response;
  } catch (error) {
    // logged on interceptors
  }
  return null;
}

async function getPublicationsTour(keywords: string[] = []): Promise<AxiosResponse<PublicationsTours> | null> {
  try {
    const route = `/tour/dashboard/list`;
    const response = await api.get(route, {params: {
      sort: 'created_at',
      sort_type: 'Decrescente',
      status: 'Ativado',
      keywords,
    }});
    return response;
  } catch (error) {
    // logged on interceptors
  }
  return null;
}

async function getPublicationsTourByUser(): Promise<AxiosResponse<PublicationsTours> | null> {
  try {
    const route = `/tour/dashboard/list`;
    const response = await api.get(route, {params: {
      sort: 'created_at',
      sort_type: 'Decrescente',
      owned_by_user: true,
      status: 'Ativado',
    }});
    return response;
  } catch (error) {
    // logged on interceptors
  }
  return null;
}

async function getPublicationByID(publicationID: string, isPublic=false): Promise<Publication | null> {
  try {
    const privateRoute = `/publication/dashboard/specific/${publicationID}`;
    const publicRoute = `/publication/dashboard/specific/public/${publicationID}`;
    const route = isPublic ? publicRoute : privateRoute;

    const response = await api.get(route);
    return response.data;
  } catch (error) {
    // logged on interceptors
  }
  return null;
}

async function getTourByID(tourID: string): Promise<PublicationTourOnline | null> {
  try {
    const route = `/tour/dashboard/specific/${tourID}`;
    const response = await api.get(route);
    return response.data;
  } catch (error) {
    // logged on interceptors
  }
  return null;
}

async function sendPublicationRate(publicationID: string, rate: number): Promise<AxiosResponse | null> {
  try {
    const route = `/publication/dashboard/${publicationID}/rate`;
    const response = await api.patch(route, {rate});
    return response;
  } catch (error) {
    // logged on interceptors
  }
  return null;
}

export {
  sendPublication,
  getPublicationsTourByUser,
  getSortedPublicationsByUser,
  getPublicPublications,
  getPublications,
  getPublicationByID,
  sendPublicationRate,
  getLastVideoSent,
  deleteVideo,
  deleteTour,
  getTourByID,
  editPublication,
  getMostViewedVideo,
  sendPublicationTour,
  editPublicationTour,
  getPublicationsTour,
  getPublicationsLives,
};
