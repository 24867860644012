/* eslint-disable require-jsdoc */
import React from 'react';
import {NextRouteContext} from 'context/NextRouteContext';
import {NextRouteProps} from 'context/NextRouteContext';

function useNextRoute(): NextRouteProps {
  const context = React.useContext(NextRouteContext);

  if (!context) {
    throw new Error('useNextRoute must be used within AuthProvider');
  }

  return context;
}

export default useNextRoute;
