/* eslint-disable max-len */
import React, {useCallback, useMemo} from 'react';
import {useState} from 'react';
import {PropsWithChildren} from 'react';
import {createContext} from 'react';

export interface NextRouteProps {
  hasNextRoute: boolean
  memorizeNextRoute: (nextRoute: string | null ) => void
  getNextRoute: () => string | null
}

interface AppProviderProps {
  children: React.ReactNode
}

const NextRouteContext = createContext<NextRouteProps>({} as NextRouteProps);


const NextRouteProvider: React.FC<PropsWithChildren<AppProviderProps>> = ({children}) => {
  const [nextRoute, setNextRoute] = useState<string | null>(null);

  const memorizeNextRoute = useCallback((nextRoute: string | null) => {
    setNextRoute(nextRoute);
  }, []);

  const hasNextRoute = useMemo(() => {
    return nextRoute !== null;
  }, [nextRoute]);

  const getNextRoute = useCallback(() => {
    const response = nextRoute;
    nextRoute && setNextRoute(null);

    return response;
  }, [nextRoute, setNextRoute]);

  return (
    <NextRouteContext.Provider value={{memorizeNextRoute, getNextRoute, hasNextRoute}}>
      {children}
    </NextRouteContext.Provider>
  );
};


export {NextRouteProvider, NextRouteContext};
