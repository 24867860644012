/* eslint-disable max-len */
import React, {useCallback, useMemo} from 'react';
import pallette from 'colors';
import {Container, ButtonPropsContainer, Span} from './styles';
import {Container as Box} from 'components/Container';
import './index.css';

interface ButtonProps extends ButtonPropsContainer {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  StartIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  EndIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  children?: string
  outline?: boolean
  contained?: boolean
  link?: boolean
  type?: 'button' | 'submit' | 'reset'
  iconSize?: number


  mh?: string, // margin-horizontal
  mv?: string, // margin-vertical
  ph?: string, // padding-horizontal
  pv?: string, // padding-vertical

  ml?: string, // margin-left
  mr?: string, // margin-right
  mt?: string, // margin-top
  mb?: string, // margin-bottom

  pl?: string, // padding-left
  pr?: string, // padding-right
  pt?: string, // padding-top
  pb?: string, // padding-bottom

  p?: string
  m?: string
}

type Event = React.MouseEvent<HTMLButtonElement, MouseEvent>

const Button: React.FC<ButtonProps> = ({onClick, StartIcon, EndIcon, iconSize, ...props}) => {
  const [coords, setCoords] = React.useState({x: -1, y: -1});
  const [isRippling, setIsRippling] = React.useState(false);
  const {transparent, backgroundButton, buttonHover, white} = pallette;

  React.useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  React.useEffect(() => {
    if (!isRippling) setCoords({x: -1, y: -1});
  }, [isRippling]);

  const clickEffect = useCallback((e: Event) => {
    const node = e.target as HTMLElement;
    const rect = node.getBoundingClientRect();
    setCoords({x: e.clientX - rect.left, y: e.clientY - rect.top});
  }, []);

  const background = useMemo(() => {
    return props.contained ? backgroundButton : transparent;
  }, [props]);

  const backgroundHover = useMemo(() => {
    return props.contained ? buttonHover : transparent;
  }, [props]);

  const textColor = useMemo(() => {
    return props.outline ? backgroundButton : white;
  }, [props]);

  const borderButton = useMemo(() => {
    return props.outline ? backgroundButton : undefined;
  }, [props]);

  const clickHandler = (e: any) => {
    clickEffect(e);
    if (onClick) {
      return onClick(e);
    }
  };

  return (
    <Container
      className='button'
      onClick={clickHandler}
      background={props.background || background}
      backgroundHover={props.backgroundHover ||backgroundHover}
      textColor={textColor}
      border={borderButton}
      p={props.outline ? '9px' : undefined}
      type={props.type}
      {...props}
    >
      <Box direction='row' gap='.5rem' align='center' justify='center'>
        {StartIcon && (
          <StartIcon width={iconSize || 20} height={iconSize || 20} />
        )}
        <Span textAlign={props.textAlign}>{props.children}</Span>
        {EndIcon && (
          <EndIcon width={iconSize || 20} height={iconSize || 20} />
        )}
      </Box >
      {isRippling && (
        <span className="ripple" style={{left: coords.x, top: coords.y}} />
      )}
    </Container>
  );
};

export {Button};
export default Button;
