import axios from 'axios';
import {ApiResponse} from './types';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const timeout = 1000 * 20;


const validateStatus = (status: number | null): boolean => {
  if (!status) return false;
  return status >= 200 && status < 300;
};

export const defaultApiResponse: ApiResponse = {
  data: null,
  status: null,
  message: null,
  error: false,
};

const api = axios.create({
  baseURL,
  timeout,
});


export {api, validateStatus};
