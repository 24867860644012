import {createGlobalStyle} from 'styled-components';
import NunitoBold from 'assets/fonts/Nunito/Nunito-Bold.ttf';
import NunitoSemiBold from 'assets/fonts/Nunito/Nunito-SemiBold.ttf';
import NunitoRegular from 'assets/fonts/Nunito/Nunito-Regular.ttf';
import NunitoMedium from 'assets/fonts/Nunito/Nunito-Medium.ttf';
import NunitoLight from 'assets/fonts/Nunito/Nunito-Light.ttf';
import NunitoExtraBold from 'assets/fonts/Nunito/Nunito-ExtraBold.ttf';
import pallette from 'colors';

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'Nunito ExtraBold';
    src: local('Nunito ExtraBold'), url(${NunitoExtraBold}) format("truetype");
    font-style: normal;
  }

  @font-face {
    font-family: 'Nunito Bold';
    src: local('Nunito Bold'), url(${NunitoBold}) format("truetype");
    font-style: normal;
  }

  @font-face {
    font-family: 'Nunito SemiBold';
    src: local('Nunito SemiBold'), url(${NunitoSemiBold}) format("truetype");
    font-style: normal;
  }

  @font-face {
    font-family: 'Nunito Medium';
    src: local('Nunito Medium'), url(${NunitoMedium}) format("truetype");
    font-style: normal;
  }

  @font-face {
    font-family: 'Nunito Regular';
    src: local('Nunito Regular'), url(${NunitoRegular}) format("truetype");
    font-style: normal;
  }

  @font-face {
    font-family: 'Nunito Light';
    src: local('Nunito Light'), url(${NunitoLight}) format("truetype");
    font-style: normal;
  }


  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    user-select: none;
  }

  body {
    overflow-x: hidden;
    background-color: ${pallette.primaryDarkLilac};
  }

  button {
    font-family: 'Nunito Medium', sans-serif;
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6, p span {
    margin: 0px;
    padding: 0px;
    color: white;
  }

  h1 {
    font-family: 'Nunito Bold', sans-serif;
    color: white;
  }

  h2, h3 {
    font-family: 'Nunito SemiBold', sans-serif;
    color: white;
  }

  h4, h5, h6 {
    font-family: 'Nunito Medium', sans-serif;
    color: white;
  }

  p, label, span {
    font-family: 'Nunito Regular', sans-serif;
    color: white;
  }
`;

export default GlobalStyles;
