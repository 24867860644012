/* eslint-disable max-len */
import styled from 'styled-components';
export interface TextProps {
  flex?: number | string,
  color?: string
  width?: string
  maxWidth?: string
  minWidth?: string
  fontSize?: number | string
  lineHeight?: number
  marginVertical?: string
  marginHorizontal?: string
  textAlign?: 'center' | 'left' | 'right' | 'justify'
  textOverflow?: 'clip' | 'ellipsis'
}

export const h1 = styled.h1<TextProps>`
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;

export const h2 = styled.h2<TextProps>`
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;

export const h3 = styled.h3<TextProps>`
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;

export const h4 = styled.h4<TextProps>`
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;

export const h5 = styled.h5<TextProps>`
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;

export const h6 = styled.h6<TextProps>`
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;

export const p = styled.p<TextProps>`
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;

export const light = styled.p<TextProps>`
  font-family: 'Nunito Light', sans-serif;
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;

export const extrabold = styled.h1<TextProps>`
  font-family: 'Nunito ExtraBold', sans-serif;
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;

export const link = styled.span<TextProps>`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: white;
  color: ${({color}) => color && color};
  flex: ${({flex}) => flex ? flex : 'none'};
  width: ${({width}) => width && width};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-width: ${({minWidth}) => minWidth && minWidth};
  font-size: ${({fontSize}) => fontSize && typeof fontSize === 'string' ? fontSize : `${fontSize}pt`};
  text-align: ${({textAlign}) => textAlign && textAlign};
  line-height: ${({lineHeight}) => lineHeight && lineHeight};
  margin-left: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-right: ${({marginHorizontal}) => marginHorizontal && marginHorizontal};
  margin-top: ${({marginVertical}) => marginVertical && marginVertical};
  margin-bottom: ${({marginVertical}) => marginVertical && marginVertical};
  text-overflow: ${({textOverflow}) => textOverflow && textOverflow};
`;


export const variants = {h1, h2, h3, h4, h5, h6, p, light, extrabold, link};
