/* eslint-disable max-len */
import React from 'react';
import {useState} from 'react';
import {PropsWithChildren} from 'react';
import {createContext} from 'react';
import {getPublicationByID, getPublicPublications} from 'services/publications';
import {PublicationSimplified, Publication} from 'services/types';
import {PublicationTourOnline} from 'types/publications';
import {createPublicationMap} from 'utils/publications';

import {
  getPublications, 
  getPublicationsTour, 
  getPublicationsTourByUser, 
  getTourByID,
} from 'services/publications';


export interface PublicationsMap {
  [key: string]: PublicationSimplified;
}

export interface PublicationContextProps {
  tourInfo: PublicationTourOnline | null
  publications: PublicationsMap;
  publication: Publication | null;
  publicationsTour: PublicationTourOnline[]
  fetchTourPublications: () => Promise<void>
  fetchTourPublicationsByUser: () => Promise<void>
  fetchPublications: () => Promise<void>
  fetchPublicPublications: () => Promise<void>
  fetchPublicationByID: (publicationID: string, isPublic?: boolean) => Promise<void>
  fetchTourByID: (tourId: string) => Promise<void>
  searchPublications: (pattern: string) => Promise<void>
  searchPublicPublications: (pattern: string) => Promise<void>
  searchTours: (pattern: string) => Promise<void>
}

interface PublicationsProviderProps {
  children: React.ReactNode
}

const PublicationContext = createContext<PublicationContextProps>({} as PublicationContextProps);

const PublicationsProvider: React.FC<PropsWithChildren<PublicationsProviderProps>> = ({children}) => {
  const [publications, setPublications] = useState<PublicationsMap>({});
  const [publication, setPublication] = useState<Publication | null>(null);
  const [publicationsTour, setPublicationsTour] = useState<PublicationTourOnline[]>([]);
  const [tourInfo, setTourInfo] = useState<PublicationTourOnline | null >(null); 

  const fetchTourByID = async (tourId: string): Promise<void> => {
    const tour = await getTourByID(tourId);
    setTourInfo(tour);
  };
  
  const fetchTourPublications = async () => {
    const response = await getPublicationsTour();
    if (response && response.data) {
      const {object_list: publicationsArray} = response.data;
      setPublicationsTour(publicationsArray);
    }
  };
  

  const fetchTourPublicationsByUser = async () => {
    const response = await getPublicationsTourByUser();
    if (response && response.data) {
      const {object_list: publicationsArray} = response.data;
      setPublicationsTour(publicationsArray);
    }
  };


  const fetchPublicationByID = async (publicationID: string, isPublic=false): Promise<void> => {
    const fetchedPublication = await getPublicationByID(publicationID, isPublic);
    setPublication(fetchedPublication);
  };

  const searchPublications = async (searchPattern: string) => {
    const keywords = searchPattern.split(' ').filter((pattern) => pattern.length !== 0);
    const searchResults = await getPublications(keywords);
    if (searchResults && searchResults.data) {
      const {object_list: publicationsArray} = searchResults.data;
      const publicationsMap = createPublicationMap(publicationsArray); 
      setPublications(publicationsMap);
    }
  };

  const searchTours = async (searchPattern: string) => {
    const keywords = searchPattern.split(' ').filter((pattern) => pattern.length !== 0); 
    const searchResults = await getPublicationsTour(keywords);
    if (searchResults && searchResults.data) {
      const {object_list: items} = searchResults.data;
      setPublicationsTour(items);
    }
  };


  const fetchPublications= async () => {
    const response = await getPublications();
    if (response && response.data) {
      const {object_list: publicationsArray} = response.data;
      const publicationsMap = createPublicationMap(publicationsArray); 
      setPublications(publicationsMap);
    }
  };
  
  const fetchPublicPublications = async () => {
    const response = await getPublicPublications();
    if (response && response.data) {
      const {object_list: publicationsArray} = response.data;
      const publicationsMap = createPublicationMap(publicationsArray); 
      setPublications(publicationsMap);
    }
  };

  const searchPublicPublications = async (searchPattern: string) => {
    const keywords = searchPattern.split(' ').filter((pattern) => pattern.length !== 0);
    const searchResults = await getPublications(keywords);
    if (searchResults && searchResults.data) {
      const {object_list: publicationsArray} = searchResults.data;
      const publicationsMap = createPublicationMap(publicationsArray); 
      setPublications(publicationsMap);
    }
  };

  
  return (
    <PublicationContext.Provider value={{
      tourInfo,
      publication, 
      publications, 
      publicationsTour,
      fetchPublications,
      fetchTourPublications,
      fetchTourPublicationsByUser,
      searchPublicPublications,
      fetchPublicPublications,
      fetchPublicationByID,
      searchPublications,
      fetchTourByID,
      searchTours,
    }}>
      {children}
    </PublicationContext.Provider>
  );
};


export {PublicationContext, PublicationsProvider};
