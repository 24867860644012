import React, {useEffect} from 'react';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {RouteProps} from 'types';
import useAuth from 'hooks/Auth';
import useNextRoute from 'hooks/NextRoute';


const IsPrivate: React.FC<RouteProps> = ({Component}) => {
  const {auth} = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const {hasNextRoute, getNextRoute, memorizeNextRoute} = useNextRoute();

  useEffect(() => {
    if (!auth.user) {
      const {pathname} = location;
      memorizeNextRoute(pathname);
    }

    if (auth.user && hasNextRoute) {
      const nextRoute = getNextRoute();
      nextRoute && navigate(nextRoute);
    }
  }, []);

  return auth.user && !hasNextRoute ? Component : <Navigate to={'/signin'} />;
};

export default IsPrivate;
