import React, {useMemo} from 'react';
import {v4 as uuidv4} from 'uuid';
import {variants, TextProps} from './styles';

interface Props extends TextProps {
  variant?: keyof typeof variants,
  children?: React.ReactNode,
  onClick?: React.MouseEventHandler<HTMLHeadingElement> | undefined
}

const Typography: React.FC<Props> = ({variant, children, onClick, ...rest}) => {
  const Text = useMemo(() => {
    return variant ? variants[variant] : variants.p;
  }, [variant]);

  return (
    <Text 
      key={uuidv4()}
      onClick={onClick} 
      {...rest} 
    >
      {children}
    </Text>
  );
};

export {Typography};
export default Typography;
