import styled from 'styled-components';
import pallette from 'colors';
import * as RadixDialog from '@radix-ui/react-dialog';

interface ContentProps {
  type: 'success' | 'error';
}

const Root = styled(RadixDialog.Root)``;

const Portal = styled(RadixDialog.Portal)``;

const Close = styled(RadixDialog.Close)``;

const Overlay = styled(RadixDialog.Overlay)`
  background-color: rgb(25,1,31, 0.6);
  position: fixed;
  inset: 0;
  animation: ease-in 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;

const ContentLayerOne = styled.div`
  width: 100%;
  height: 100%;

  background: linear-gradient(137.99deg, rgba(55, 63, 56, 0.24) -9.46%,
  rgba(62, 66, 62, 0.35) 30.64%, rgba(0, 0, 0, 0.5) 109.01%);
  mix-blend-mode: screen;
`;

const ContentLayerTwo = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  background: rgba(38, 36, 38, 0.5);
  box-shadow: inset 23.3px -23.3px 23.3px rgba(138, 77, 167, 0.1),
  inset -23.3px 23.3px 23.3px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(23.3px);

`;

const Content = styled(RadixDialog.Content)<ContentProps>`

  border-image: ${({type}) => type === 'success' ?
    pallette.borderSuccess :
    pallette.borderError};

  background: rgba(38, 36, 38, 0.5);
  box-shadow: inset 23.3px -23.3px 23.3px rgba(138, 77, 167, 0.1),
              inset -23.3px 23.3px 23.3px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(23.3px);


  border-radius: 1rem;
  border-width: 10px;
  border-style: solid;

  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 450px;
  height: 450px;


  display: flex;
  justify-content: center;
  align-items: center;

  animation: ease-in 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;


export const StyledDialog = {
  Root,
  Overlay,
  Portal,
  Close,
  Content,
  ContentLayerOne,
  ContentLayerTwo,
};
