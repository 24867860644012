import React from 'react';
import BrowserRoutes from './routes';
import {AuthProvider} from 'context/AuthContext';
import {NextRouteProvider} from 'context/NextRouteContext';
import {PublicationsProvider} from 'context/PublicationContext';
import {NotificationsProvider} from 'context/NotificationContext';

const App: React.FC = () => {
  return (
    <NotificationsProvider>
      <NextRouteProvider>
        <AuthProvider>
          <PublicationsProvider>
            <BrowserRoutes />
          </PublicationsProvider>
        </AuthProvider>
      </NextRouteProvider>
    </NotificationsProvider>
  );
};

export default App;
