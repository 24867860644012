class Storage {
  static save = (key: string, value: any) => {
    const data = typeof value === 'string' ? value : JSON.stringify(value);
    return localStorage.setItem(key, data);
  };

  static delete = (key: string) => {
    return localStorage.removeItem(key);
  };

  static get = (key: string) => {
    return localStorage.getItem(key); 
  };
}


export default Storage;
