import React, {
  PropsWithChildren,
  createContext,
  useMemo,
  useState,
} from 'react';
import {
  DialogType,
  NotificationDialog,
} from 'components/NotificationDialog';

import pallette from 'colors';
import Button from 'components/Button';


interface NotificationOptions {
  title?: string,
  subtitle?: string,
  type?: DialogType
  action?: Action,
}

interface Action {
  title: string
  onClickHandler(): void
}


export interface NotificationContextProps {
  notifySuccess: (options: NotificationOptions) => void
  notifyError: (options: NotificationOptions) => void
}

interface NotificationsProviderProps {
  children: React.ReactNode
}

const NotificationContext = createContext<NotificationContextProps>(
  {} as NotificationContextProps,
);


const NotificationsProvider:
  React.FC<PropsWithChildren<NotificationsProviderProps>> = ({children}) => {
    const [open, setOpen] = useState(false);
    const [notification, setNotification] = useState<NotificationOptions>(
      {} as NotificationOptions,
    );


    const notifySuccess = (options: NotificationOptions) => {
      setNotification({...options, type: 'success'});
      setOpen(true);
    };

    const notifyError = (options: NotificationOptions) => {
      setNotification({...options, type: 'error'});
      setOpen(true);
    };

    const onClickAction = (actionHandler: () => void) => {
      actionHandler();
      setOpen(false);
    };

    const action = useMemo(() => {
      if (notification.action === undefined) {
        return null;
      }

      const {onClickHandler, title} = notification.action;

      return (
        <Button
          link
          width='50%'
          onClick={() => onClickAction(onClickHandler)}
          textColor={pallette.link}
        >
          {title}
        </Button>
      );
    }, [notification]);


    return (
      <NotificationContext.Provider value={{
        notifyError,
        notifySuccess,
      }}>
        {children}
        <NotificationDialog
          open={open}
          onOpenChange={setOpen}
          title={notification.title}
          subtitle={notification.subtitle}
          type={notification.type}
        >
          {action}
        </NotificationDialog>
      </NotificationContext.Provider>
    );
  };


export {NotificationContext, NotificationsProvider};
