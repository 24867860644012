/* eslint-disable max-len */
import styled, {css} from 'styled-components';

export type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
export type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';
export type FlexAlign = 'baseline' | 'stretch' | 'center' | 'flex-start' | 'flex-end';
export type FlexJustify = 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-evenly' | 'space-between';
export type Position = 'static' | 'relative' | 'fixed' | 'absolute' | 'sticky'
export type Sizing = 'border-box' | 'content-box'
export type Visibility = 'visible' | 'hidden' | 'collapse'


export interface FlexContainerProps {
  position?: Position,
  flex?: number | string,
  direction?: FlexDirection,
  wrap?: FlexWrap,
  align?: FlexAlign,
  justify?: FlexJustify,
  radius?: string
  maxWidth?: string
  minHeight?: string
  gap?: string,
  backgroundColor?: string,
  backgroundImage?: string,
  background?: string,
  boxSizing?: Sizing
  width?: string,
  height?: string,
  zIndex?: number,
  mixBlendMode?: string;
  visibility?: Visibility
  border?: string

  mh?: string, // margin-horizontal
  mv?: string, // margin-vertical
  ph?: string, // padding-horizontal
  pv?: string, // padding-vertical

  ml?: string, // margin-left
  mr?: string, // margin-right
  mt?: string, // margin-top
  mb?: string, // margin-bottom

  pl?: string, // padding-left
  pr?: string, // padding-right
  pt?: string, // padding-top
  pb?: string, // padding-bottom

  p?: string // padding
  m?: string // margin
}

export const ContainerFlex = styled.div<FlexContainerProps>`
  display: flex;
  flex: ${({flex}) => flex && flex }; 
  width: ${({width}) => width && width};
  position: ${({position}) => position && position};
  background-color: ${({backgroundColor}) => backgroundColor && backgroundColor};
  background: ${({background}) => background && background};
  max-width: ${({maxWidth}) => maxWidth && maxWidth};
  min-height: ${({minHeight}) => minHeight && minHeight};
  flex-direction: ${({direction}) => direction && direction}; 
  flex-wrap: ${({wrap}) => wrap && wrap}; 
  align-items: ${({align}) => align && align};
  justify-content: ${({justify}) => justify && justify};
  height: ${({height}) => height && height};
  box-sizing: ${({boxSizing}) => boxSizing && boxSizing};
  border-radius: ${({radius}) => radius && radius};
  border: ${({border}) => border && `1px solid ${border}`};
  z-index: ${({zIndex}) => zIndex && zIndex};
  gap: ${({gap}) => gap && gap};
  mix-blend-mode: ${({mixBlendMode}) => mixBlendMode && mixBlendMode};
  visibility: ${({visibility}) => visibility && visibility};

  margin: ${({m}) => m && m};
  padding: ${({p}) => p && p};

  margin-left: ${({ml}) => ml && ml};
  margin-right: ${({mr}) => mr && mr};
  margin-top: ${({mt}) => mt && mt};
  margin-bottom: ${({mb}) => mb && mb};

  padding-left: ${({pl}) => pl && pl};
  padding-right: ${({pr}) => pr && pr};
  padding-top: ${({pt}) => pt && pt};
  padding-bottom: ${({pb}) => pb && pb};
  

  ${({mh}) => mh && css`
    margin-left: ${mh};
    margin-right: ${mh};
  `};

  ${({mv}) => mv && css`
    margin-top: ${mv};
    margin-bottom: ${mv};
  `};

  ${({pv}) => pv && css`
    padding-top: ${pv};
    padding-bottom: ${pv};
  `};

  ${({ph}) => ph && css`
    padding-left: ${ph};
    padding-right: ${ph};
  `};

  ${({backgroundImage}) => backgroundImage && css`
    background-image: url(${backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
  `}
`;
